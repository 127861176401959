@import url('https://fonts.googleapis.com/css2?family=Urbanist');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro');

body {
  margin: 0;
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Urbanist;
}
